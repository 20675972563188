import React, { useEffect, useState } from "react"
import styled, { css } from "styled-components"

import * as breakpoints from "../core/breakpoints"

export const columnsCount = 12

const gutterWidths = { m: 8, t: 12, tl: 20, d: 40, dl: 40 }
const maxWidth = 1860
const sideMargins = { m: 10, t: 36, tl: 30, d: 60, dl: 60 }

export const Grid = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: ${maxWidth + sideMargins.dl * 2}px;
  padding: 0 ${sideMargins.m - gutterWidths.m / 2}px;
  z-index: 999;

  ${(props) =>
    props.hasOwnProperty("m-vAlign") &&
    css`
      align-items: ${props["m-vAlign"] ?? "flex-start"};
    `}

  ${Object.entries(breakpoints).map(
    ([name, width]) => css`
      ${(props) =>
        props.hasOwnProperty(`${name}-vAlign`) &&
        css`
          align-items: ${props[`${name}-vAlign`] ?? "flex-start"};
        `}

      @media screen and (min-width: ${width}px) {
        padding: 0 ${sideMargins[name] - gutterWidths[name] / 2}px;
      }
    `
  )}
`

export const Column = styled.div`
  ${(props) =>
    Object.entries(breakpoints).reduce(
      (previous, [name, width]) => [
        ...previous,
        css`
          flex-basis: ${(100 / columnsCount) * (props.m ?? 1)}%;
          max-width: ${(100 / columnsCount) * (props.m ?? 1)}%;
          padding: 0 ${gutterWidths.m / 2}px;

          ${
            props.hasOwnProperty("m-offset") &&
            css`
              margin-left: ${(100 / columnsCount) * props["m-offset"] ?? 0}%;
            `
          }

          ${
            props.hasOwnProperty("m-align") &&
            css`
              text-align: ${props["m-align"] ?? "left"};
            `
          }

          @media screen and (min-width: ${width}px) {
            padding: 0 ${gutterWidths[name] / 2}px;

            ${
              props.hasOwnProperty(name) &&
              css`
                flex-basis: ${(100 / columnsCount) * (props?.[name] ?? 1)}%;
                max-width: ${(100 / columnsCount) * (props?.[name] ?? 1)}%;
              `
            }

            ${
              props.hasOwnProperty(`${name}-offset`) &&
              css`
                margin-left: ${(100 / columnsCount) * props[`${name}-offset`] ??
                0}%;
              `
            }

            ${
              props.hasOwnProperty(`${name}-align`) &&
              css`
                text-align: ${props[`${name}-align`] ?? "left"};
              `
            }
          }
        `,
      ],
      [
        css`
          box-sizing: border-box;
        `,
      ]
    )}
`

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 ${-gutterWidths.m / 2}px;

  ${Object.entries(breakpoints).map(
    ([name, width]) => css`
      @media screen and (min-width: ${width}px) {
        margin: 0 ${-gutterWidths[name] / 2}px;
      }
    `
  )}
`

export const Helper = () => {
  const [isVisible, setIsVisible] = useState(
    window?.localStorage?.getItem("x-grid") === "true" ?? false
  )

  useEffect(() => {
    document.addEventListener("keydown", handleKeydown, false)

    return () => document.removeEventListener("keydown", handleKeydown, false)
  }, [])

  useEffect(() => {
    window.localStorage.setItem("x-grid", isVisible)
  }, [isVisible])

  const handleKeydown = (event) => {
    if (event.keyCode !== 71) {
      return
    }

    setIsVisible((isVisible) => !isVisible)
  }

  return (
    isVisible && (
      <div
        style={{
          height: "100%",
          left: 0,
          pointerEvents: "none",
          position: "fixed",
          top: 0,
          width: "100%",
          zIndex: 999,
        }}
      >
        <Grid>
          {new Array(12).fill().map((_, index) => (
            <Column key={index}>
              <div
                style={{
                  backgroundColor: "rgba(255, 99, 71, 0.25)",
                  minHeight: "100vh",
                }}
              />
            </Column>
          ))}
        </Grid>
      </div>
    )
  )
}
