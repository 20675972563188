import PropTypes from "prop-types"
import React from "react"
import Helmet from "react-helmet"
import url from "url"

const SEO = ({ description, image, siteUrl, title, twitter }) => (
  <Helmet
    htmlAttributes={{
      lang: "en",
    }}
    title={title}
    meta={[
      {
        name: "description",
        content: description,
      },
      {
        property: "og:title",
        content: title,
      },
      {
        property: "og:description",
        content: description,
      },
      {
        property: "og:type",
        content: "website",
      },
      {
        property: "og:image",
        content: url.resolve(siteUrl, image.facebookLocal.src),
      },
      {
        property: "og:url",
        content: url.resolve(siteUrl, "/"),
      },
      {
        name: "twitter:card",
        content: "summary_large_image",
      },
      {
        name: "twitter:creator",
        content: twitter,
      },
      {
        name: "twitter:site",
        content: twitter,
      },
      {
        name: "twitter:title",
        content: title,
      },
      {
        name: "twitter:image:src",
        content: url.resolve(siteUrl, image.twitterLocal.src),
      },
      {
        name: "twitter:description",
        content: description,
      },
    ]}
  />
)

SEO.propTypes = {
  description: PropTypes.string.isRequired,
  image: PropTypes.shape({
    facebookLocal: PropTypes.shape({ src: PropTypes.isRequired }).isRequired,
    twitterLocal: PropTypes.shape({ src: PropTypes.isRequired }).isRequired,
  }).isRequired,
  siteUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  twitter: PropTypes.string.isRequired,
}

export default SEO
