import React, { useCallback, useEffect, useRef } from "react"
import { createPortal } from "react-dom"
import url from "url"

import * as S from "./styles"

const TOOLTIP_OFFSET = 20

export const useTooltip = () => {
  const events = useRef([])
  const isVisible = useRef(false)
  const isTouchEvent = useRef(false)
  const tooltipRef = useRef(null)
  const width = useRef(0)

  useEffect(() => {
    const handleMousemove = ({ clientX, clientY }) => {
      move(clientX, clientY)
    }

    const handleResize = () => {
      resize()
    }

    document.addEventListener("mousemove", handleMousemove, false)
    window.addEventListener("resize", handleResize, false)

    return () => {
      document.removeEventListener("mousemove", handleMousemove, false)
      window.removeEventListener("resize", handleResize, false)

      // eslint-disable-next-line react-hooks/exhaustive-deps
      events.current.forEach((removeEvent) => {
        removeEvent()
      })
    }
  }, [])

  const handleAnimationEnd = () => {
    reset()
  }

  const move = (x, y) => {
    if (!isVisible.current) {
      return
    }

    const offset =
      x + width.current + TOOLTIP_OFFSET > window.innerWidth
        ? 0 - (width.current + TOOLTIP_OFFSET)
        : TOOLTIP_OFFSET

    tooltipRef.current.style.left = x + offset + "px"
    tooltipRef.current.style.top = y + "px"
  }

  const ready = () => {
    const isReady = !isTouchEvent.current

    isTouchEvent.current = false

    return isReady
  }

  const reset = () => {
    isVisible.current = false
    width.current = 0

    tooltipRef.current.style.left = null
    tooltipRef.current.style.top = null

    tooltipRef.current.removeAttribute("data-fade")
    tooltipRef.current.removeAttribute("data-link")
  }

  const resize = () => {
    width.current = tooltipRef.current.offsetWidth
  }

  const setRefController = useCallback((element) => {
    const handleMousedown = ({ clientX, clientY }) => {
      if (!ready()) {
        return
      }

      reset()

      tooltipRef.current.innerText = "Copied"

      activate(clientX, clientY)

      tooltipRef.current.setAttribute("data-fade", true)
    }

    const handleMouseenter = (event) => {
      if (!ready()) {
        return
      }

      reset()

      const href = event.currentTarget.href

      const mailto = /^mailto:/
      const innerText = mailto.test(href)
        ? href.replace(mailto, "")
        : url.parse(href)?.hostname

      tooltipRef.current.innerText = innerText

      activate(event.clientX, event.clientY)

      tooltipRef.current.setAttribute("data-link", true)
    }

    const handleMouseleave = () => {
      reset()
    }

    const handleTouchstart = () => {
      isTouchEvent.current = true
    }

    const activate = (x, y) => {
      resize()

      isVisible.current = true

      move(x, y)
    }

    element.addEventListener("touchstart", handleTouchstart, { passive: true })

    events.current.push(() =>
      element.removeEventListener("touchstart", handleTouchstart, {
        passive: true,
      })
    )

    if (element.tagName === "BUTTON") {
      element.addEventListener("mousedown", handleMousedown, false)

      events.current.push(() =>
        element.removeEventListener("mousedown", handleMousedown, false)
      )

      return
    }

    element.addEventListener("mouseenter", handleMouseenter, false)
    element.addEventListener("mouseleave", handleMouseleave, false)

    events.current.push(() => {
      element.removeEventListener("mouseenter", handleMouseenter, false)
      element.removeEventListener("mouseleave", handleMouseleave, false)
    })
  }, [])

  const Tooltip = () => {
    return (
      typeof window !== "undefined" &&
      createPortal(
        <S.Tooltip ref={tooltipRef} onAnimationEnd={handleAnimationEnd} />,
        document.body
      )
    )
  }

  return [setRefController, Tooltip]
}
