import styled from "styled-components"

import * as breakpoints from "../core/breakpoints"

export const Display = styled.div`
  font-family: "Banana Grotesk", sans-serif;
  font-size: 30px;
  font-weight: 300;
  line-height: 32px;

  @media screen and (min-width: ${breakpoints.t}px) {
    font-size: 54px;
    line-height: 60px;
  }

  @media screen and (min-width: ${breakpoints.tl}px) {
    font-size: 96px;
    line-height: 96px;
  }

  @media screen and (min-width: ${breakpoints.dl}px) {
    font-size: 140px;
    line-height: 136px;
  }
`

export const HighlightedBody = styled.div`
  font-family: "Banana Grotesk", sans-serif;
  font-size: 30px;
  font-weight: 300;
  line-height: 32px;

  @media screen and (min-width: ${breakpoints.t}px) {
    font-size: 44px;
    line-height: 46px;
  }

  @media screen and (min-width: ${breakpoints.tl}px) {
    font-size: 80px;
    line-height: 80px;
  }

  @media screen and (min-width: ${breakpoints.d}px) {
    font-size: 96px;
    line-height: 96px;
  }

  @media screen and (min-width: ${breakpoints.dl}px) {
    font-size: 120px;
    line-height: 126px;
  }
`

export const HighlightedBodyLink = styled.span`
  font-family: "Times", serif;
  font-size: 31px;
  font-weight: normal;
  line-height: 32px;

  @media screen and (min-width: ${breakpoints.t}px) {
    font-size: 48px;
    line-height: 46px;
  }

  @media screen and (min-width: ${breakpoints.tl}px) {
    font-size: 87px;
    line-height: 80px;
  }

  @media screen and (min-width: ${breakpoints.d}px) {
    font-size: 104px;
    line-height: 96px;
  }

  @media screen and (min-width: ${breakpoints.dl}px) {
    font-size: 134px;
    line-height: 126px;
  }
`

export const MediumBody = styled.div`
  font-family: "Banana Grotesk", sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: 26px;

  @media screen and (min-width: ${breakpoints.tl}px) {
    font-size: 29px;
    line-height: 40px;
  }

  @media screen and (min-width: ${breakpoints.d}px) {
    font-size: 37px;
    line-height: 49px;
  }

  @media screen and (min-width: ${breakpoints.dl}px) {
    font-size: 38px;
    line-height: 50px;
  }

  sup {
    font-size: 0.4em;
    vertical-align: super;
    top: auto;
  }
`

export const MediumBodyLink = styled.span`
  font-family: "Times", serif;
  font-size: 19px;
  font-weight: normal;
  line-height: 26px;

  @media screen and (min-width: ${breakpoints.tl}px) {
    font-size: 30px;
    line-height: 40px;
  }

  @media screen and (min-width: ${breakpoints.d}px) {
    font-size: 40px;
    line-height: 49px;
  }

  @media screen and (min-width: ${breakpoints.dl}px) {
    font-size: 41px;
    line-height: 50px;
  }
`

export const SmallBodyLink = styled.span`
  font-family: "Times", serif;
  font-size: 15px;
  font-weight: normal;
  line-height: 20px;

  @media screen and (min-width: ${breakpoints.d}px) {
    font-size: 22px;
    line-height: 28px;
  }
`
