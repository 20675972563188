import styled, { keyframes } from "styled-components"

import * as colors from "../core/colors"

const fade = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`

export const Tooltip = styled.span`
  background-color: ${colors.cream};
  border: 1px solid ${colors.mineshaft};
  border-radius: 2px;
  color: ${colors.mineshaft};
  padding: 4px 10px 6px;
  pointer-events: none;
  position: fixed;
  left: -999px;
  top: -999px;
  transform: translateY(-50%);
  z-index: 333;

  @media (prefers-color-scheme: dark) {
    background-color: ${colors.codgray};
    border-color: ${colors.cream};
    color: ${colors.cream};
  }

  &[data-fade] {
    animation: ${fade} 300ms cubic-bezier(0.25, 1, 0.5, 1) 1.5s both;
  }

  &[data-link] {
    background-color: ${colors.codgray};
    border-color: ${colors.fluoyellow};
    color: ${colors.fluoyellow};
  }
`
