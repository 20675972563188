import Img from "gatsby-image"
import React, { createElement, useEffect, useState } from "react"
import nl2br from "react-nl2br"
import url from "url"

import * as breakpoints from "../core/breakpoints"
import { lemongrass } from "../core/colors"
import * as G from "../Grid"
import SEO from "../seo"
import * as T from "../Text"
import { useTooltip } from "../Tooltip"
import * as S from "./styles"

const SITE_URL = "https://juliemuckensturm.fr"

const compileText = (
  { spans = [], text },
  component,
  setRefController = () => {}
) =>
  spans.reduce(
    (previous, { end, data, start }, index) => [
      ...previous,
      createElement(
        component,
        {
          as: S.Link,
          href: data.url,
          key: index,
          ref: setRefController,
          rel: "noreferrer noopener",
          target: "_blank",
        },
        <span>{text.slice(start, end)}</span>
      ),
      text.slice(end, spans[index + 1]?.start ?? Infinity),
    ],
    [text.slice(0, spans[0]?.start ?? Infinity)]
  )

const HomePage = ({ data }) => {
  const [isTabNavigating, setIsTabNavigating] = useState(false)
  const [setRefController, Tooltip] = useTooltip()

  useEffect(() => {
    document.addEventListener("keydown", handleKeydown, false)

    return () => document.removeEventListener("keydown", handleKeydown, false)
  })

  const handleCopyClick = () => {
    const textarea = document.createElement("textarea")

    document.body.appendChild(textarea)

    textarea.value = url.resolve(SITE_URL, "/")
    textarea.select()

    document.execCommand("copy")
    document.body.removeChild(textarea)
  }

  const handleKeydown = (event) => {
    if (event.keyCode !== 9) {
      return
    }

    setIsTabNavigating(true)

    document.removeEventListener("keydown", handleKeydown, false)
  }

  return (
    <>
      <SEO
        description={data.description}
        image={data.image}
        siteUrl={SITE_URL}
        title={data.title}
        twitter={data.twitter}
      />

      {process.env.NODE_ENV === "development" && <G.Helper />}

      <div>
        <S.SkipLink href="#introduction">Skip to introduction</S.SkipLink>
        <S.SkipLink href="#case-studies">Jump to case studies list</S.SkipLink>
        <S.SkipLink href="#about-me">Jump to about me</S.SkipLink>
        <S.SkipLink href="#snippets">Jump to work snippets gallery</S.SkipLink>
      </div>

      <G.Grid as={S.Metadata}>
        <G.Column m={8}>
          <G.Container>
            <G.Column m={12} t={6}>
              Paris and worldwide
            </G.Column>

            <G.Column m={12} t={6} t-align="center">
              Available from: {data.availability}
            </G.Column>
          </G.Container>
        </G.Column>

        <G.Column m={4} m-align="right">
          <T.SmallBodyLink
            as={S.Link}
            href={`mailto:${data.email}`}
            ref={setRefController}
          >
            <span>Mail me</span>
          </T.SmallBodyLink>
        </G.Column>
      </G.Grid>

      <S.Header>
        <G.Grid as={S.Whois}>
          <G.Column m={12} m-align="center">
            <T.Display as="h1" id="introduction">
              {data.whois?.[0]?.text}
            </T.Display>
          </G.Column>
        </G.Grid>

        <S.Spacer />

        <G.Grid>
          <G.Column m={12} m-align="center">
            <T.Display as="h2">{nl2br(data.job_title?.[0]?.text)}</T.Display>
          </G.Column>
        </G.Grid>
      </S.Header>

      <G.Grid id="case-studies">
        {data.works.map(({ category, image, name, url }, index) => (
          <G.Column key={index} m={12} t={6}>
            <S.GalleryItem
              href={url.url}
              rel="noreferrer noopener"
              ref={setRefController}
              target="_blank"
            >
              <S.GalleryItemImage>
                <S.GalleryItemImageFilter>
                  <Img
                    alt={image.alt}
                    fluid={{
                      ...image.local,
                      aspectRatio: 4 / 5,
                      sizes: `(min-width: ${breakpoints.t}px) 50vw, 100vw`,
                    }}
                    placeholderStyle={{ filter: "blur(10px)" }}
                  />
                </S.GalleryItemImageFilter>
              </S.GalleryItemImage>

              <G.Container>
                <G.Column m={12} tl={6} m-align="center" tl-align="left">
                  <T.SmallBodyLink as={S.GalleryItemCaption}>
                    <span>{name}</span>
                  </T.SmallBodyLink>
                </G.Column>

                <G.Column m={12} tl={6} m-align="center" tl-align="left">
                  <T.SmallBodyLink as={S.GalleryItemCaption}>
                    <span>{category}</span>
                  </T.SmallBodyLink>
                </G.Column>
              </G.Container>
            </S.GalleryItem>
          </G.Column>
        ))}
      </G.Grid>

      <G.Grid id="about-me">
        <G.Column m={12}>
          <T.HighlightedBody as={S.Biography}>
            {compileText(data.biography?.[0] ?? {}, T.HighlightedBodyLink)}
            {isTabNavigating && (
              <>
                {" "}
                <S.BiographyAccessible>
                  {compileText(
                    data.biography_accessible?.[0] ?? {},
                    T.HighlightedBodyLink,
                    setRefController
                  )}
                </S.BiographyAccessible>
              </>
            )}
          </T.HighlightedBody>
        </G.Column>
      </G.Grid>

      <G.Grid>
        <G.Column as={S.CareerList} m={10} m-offset={1} t={6} t-offset={0}>
          <S.CareerListTitle>Previously</S.CareerListTitle>

          <ul>
            {data.experiences.map(({ name, location }) => (
              <li key={name}>
                <T.MediumBody>
                  {name} <sup>{location}</sup>
                </T.MediumBody>
              </li>
            ))}

            <li>
              <T.MediumBodyLink
                as={S.Link}
                href={data.linkedin?.url}
                rel="noreferrer noopener"
                target="_blank"
              >
                <span>More on LinkedIn</span>
              </T.MediumBodyLink>
            </li>
          </ul>
        </G.Column>

        <G.Column as={S.CareerList} m={10} m-offset={1} t={6} t-offset={0}>
          <S.CareerListTitle>Recognitions</S.CareerListTitle>

          <ul>
            {data.recognitions.map(({ name, total_count }) => (
              <li key={name}>
                <T.MediumBody>
                  {name} <sup>x{total_count}</sup>
                </T.MediumBody>
              </li>
            ))}
          </ul>
        </G.Column>
      </G.Grid>

      <G.Grid as={S.Share}>
        <G.Column m={12} m-align="center">
          <S.ShareTitle>Share my portfolio</S.ShareTitle>
          <T.HighlightedBodyLink
            as={S.Button}
            onClick={handleCopyClick}
            ref={setRefController}
          >
            <span>Copy link</span>
          </T.HighlightedBodyLink>
        </G.Column>
      </G.Grid>

      <G.Grid as={S.Footer} m-vAlign="center">
        <G.Column as={S.FooterItem} m={12} m-align="center" t={6}>
          {data.credits.map(({ name, value }) => (
            <div key={name}>
              <div>{name}</div>
              <div>{compileText(value?.[0] ?? {}, T.SmallBodyLink)}</div>
            </div>
          ))}
        </G.Column>

        <G.Column as={S.FooterItem} m={12} m-align="center" t={6}>
          <div>Follow me</div>
          <div>
            {data.socials.map(({ name, url }, index) => (
              <span key={name}>
                <T.SmallBodyLink
                  as={S.Link}
                  href={url.url}
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  <span>{name}</span>
                </T.SmallBodyLink>
                {index < data.socials.length - 1 && ", "}
              </span>
            ))}
          </div>
        </G.Column>
      </G.Grid>

      <S.Snippets id="snippets">
        <G.Grid as={S.SnippetsTitle}>
          <G.Column m={12}>
            <T.MediumBody>Work snippets</T.MediumBody>
          </G.Column>
        </G.Grid>

        <G.Grid>
          {data.snippets.map(({ image1 }, index) => (
            <G.Column as={S.Snippet} key={index} m={12} t={6} d={4}>
              <Img
                alt={image1.alt}
                backgroundColor={lemongrass}
                fluid={{
                  ...image1.local,
                  aspectRatio: 1,
                  sizes: `(min-width: ${breakpoints.t}px) 50vw, (min-width: ${
                    breakpoints.d
                  }px) ${100 / 3}vw, 100vw`,
                }}
              />
            </G.Column>
          ))}
        </G.Grid>

        <G.Grid as={S.SnippetsTitle}>
          <G.Column m={12} m-align="center">
            (Woh, thanks for scrolling to the bottom.)
          </G.Column>
        </G.Grid>
      </S.Snippets>

      <Tooltip />
    </>
  )
}

export default HomePage
