export const codgray = "#171616"
export const cream = "#e5e0da"
export const fluoyellow = "#f2ff00"
export const fuscousgray = "#52524d"
export const friargray = "#7f7f7a"
export const lemongrass = "#95958f"
export const mineshaft = "#2c2c2c"
export const white = "#ffffff"

export const fade = (hex, alpha) =>
  `rgba(${hex
    .match(/^#?([\w]{2})([\w]{2})([\w]{2})$/)
    .slice(1, 4)
    .map((fragment) => parseInt(fragment, 16))
    .join(", ")}, ${alpha})`
