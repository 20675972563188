import styled from "styled-components"

import * as breakpoints from "../core/breakpoints"
import { columnsCount } from "../Grid"
import * as colors from "../core/colors"

export const Biography = styled.p`
  text-indent: ${100 / columnsCount}%;

  & > * {
    text-indent: initial;
  }
`

export const BiographyAccessible = styled.span`
  color: ${colors.friargray};
`

export const CareerList = styled.div`
  margin-top: 40px;

  @media screen and (min-width: ${breakpoints.t}px) {
    margin-top: 120px;
  }

  @media screen and (min-width: ${breakpoints.d}px) {
    margin-top: 160px;
  }
`

export const CareerListTitle = styled.div`
  @media screen and (min-width: ${breakpoints.tl}px) {
    margin-bottom: 8px;
  }
`

export const Footer = styled.footer`
  margin: 120px auto;

  @media screen and (min-width: ${breakpoints.d}px) {
    margin: 160px auto;
  }
`

export const FooterItem = styled.div`
  & + & {
    margin-top: 40px;

    @media screen and (min-width: ${breakpoints.t}px) {
      margin-top: 0px;
    }
  }
`

export const GalleryItem = styled.a`
  display: block;
  margin-bottom: 120px;
  overflow: hidden;

  &:focus {
    outline: 0px transparent;
  }

  @media screen and (min-width: ${breakpoints.d}px) {
    margin-bottom: 160px;
  }
`

export const GalleryItemImage = styled.div`
  border: 1px solid transparent;
  background-color: ${colors.cream};
  margin-bottom: 12px;
  transition: border-color 400ms cubic-bezier(0.25, 1, 0.5, 1);

  @media (prefers-color-scheme: dark) {
    border: 1px solid ${colors.codgray};
  }

  ${GalleryItem}:focus &,
  ${GalleryItem}:hover & {
    border-color: ${colors.fluoyellow};
  }

  @media screen and (min-width: ${breakpoints.d}px) {
    margin-bottom: 14px;
  }

  @media screen and (min-width: ${breakpoints.dl}px) {
    margin-bottom: 16px;
  }
`

export const GalleryItemImageFilter = styled.div`
  filter: grayscale(1);
  mix-blend-mode: multiply;
  transition: filter 400ms cubic-bezier(0.25, 1, 0.5, 1);

  ${GalleryItem}:focus &,
  ${GalleryItem}:hover & {
    filter: none;
  }
`

export const GalleryItemCaption = styled.div`
  transition: transform 300ms cubic-bezier(0.25, 1, 0.5, 1);

  & > span {
    border-bottom: 1px solid transparent;
  transition: border-color 300ms cubic-bezier(0.25, 1, 0.5, 1);
  }

  ${GalleryItem}:focus &,
  ${GalleryItem}:hover & {
    outline: 0px transparent;
    transform: translateY(-0.075em);
  }

  ${GalleryItem}:focus & > span,
  ${GalleryItem}:hover & > span {
    border-bottom-color: ${colors.fluoyellow};
  }
`

export const Header = styled.header`
  box-sizing: border-box;
  margin-bottom: 120px;

  @media screen and (min-width: ${breakpoints.tl}px) {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    min-height: calc(100vh - 64px - 20px);
    padding-bottom: 48px;
  }

  @media screen and (min-width: ${breakpoints.d}px) {
    min-height: calc(100vh - 92px - 20px);
  }

  @media screen and (min-width: ${breakpoints.dl}px) {
    padding-bottom: 80px;
  }
`

export const Link = styled.a`
  display: inline-block;
  transition: transform 300ms cubic-bezier(0.25, 1, 0.5, 1);

  & > span {
    border-bottom: 1px solid transparent;
    position: relative;
    transition: border-color 300ms cubic-bezier(0.25, 1, 0.5, 1);
  }

  &:hover,
  &:focus {
    border-bottom-color: ${colors.fluoyellow};
    outline: 0px transparent;
    transform: translateY(-0.075em);
  }

  &:hover > span,
  &:focus > span {
    border-bottom-color: ${colors.fluoyellow};
  }
`

export const Button = Link.withComponent("button")

export const Metadata = styled.div`
  margin-bottom: 120px;
  padding-top: 8px;
  position: sticky;
  top: 0;
  z-index: 111;

  @media screen and (min-width: ${breakpoints.t}px) {
    padding-top: 16px;
  }

  @media screen and (min-width: ${breakpoints.tl}px) {
    margin-bottom: 24px;
    padding-top: 20px;
  }

  @media screen and (min-width: ${breakpoints.d}px) {
    padding-top: 40px;
  }
`

export const Share = Footer

export const ShareTitle = styled.div`
  margin-bottom: 8px;

  @media screen and (min-width: ${breakpoints.t}px) {
    margin-bottom: 10px;
  }

  @media screen and (min-width: ${breakpoints.tl}px) {
    margin-bottom: 20px;
  }
`

export const Spacer = styled.div`
  @media screen and (min-width: ${breakpoints.tl}px) {
    flex-grow: 1;
  }
`

export const SkipLink = styled.a`
  background-color: #7e9199;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  clip: rect(0 0 0 0);
  color: ${colors.white};
  display: block;
  height: 1px;
  left: 50%;
  margin: -1px;
  overflow: hidden;
  padding: 9px 20px 12px;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  z-index: 222;
  white-space: nowrap;
  width: 1px;

  &:focus {
    clip: auto;
    width: auto;
    overflow: visible;
    outline: 0px transparent;
    height: auto;
  }
`

export const Snippet = styled.div`
  & + & {
    margin-top: 10px;
  }

  @media screen and (min-width: ${breakpoints.t}px) {
    & + & {
      margin-top: 0;
    }

    &:nth-child(n + 3) {
      margin-top: 12px;
    }
  }

  @media screen and (min-width: ${breakpoints.tl}px) {
    &:nth-child(n + 3) {
      margin-top: 20px;
    }
  }

  @media screen and (min-width: ${breakpoints.d}px) {
    &:nth-child(n + 3) {
      margin-top: 0;
    }

    &:nth-child(n + 4) {
      margin-top: 40px;
    }
  }
`

export const Snippets = styled.div`
  background-color: ${colors.friargray};
  color: ${colors.cream};
  overflow: hidden;
`

export const SnippetsTitle = styled.div`
  margin: 80px auto;

  @media screen and (min-width: ${breakpoints.d}px) {
    margin: 120px auto;
  }
`

export const Whois = styled.div`
  margin-bottom: 120px;

  @media screen and (min-width: ${breakpoints.tl}px) {
    margin-bottom: 96px;
  }
`
