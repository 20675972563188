import React from "react"

import HomePage from "../components/HomePage"

const IndexPage = ({ data }) => <HomePage data={data.homepage} />

export const query = `
  fragment ExternalUrl on Link {
    ... on LinkWeb {
      url
    }
  }

  query {
    homepage {
      availability
      biography
      biography_accessible
      credits
      description
      email
      experiences
      image {
        facebookLocal: local(height: 630, width: 1200) {
          src
        }
        twitterLocal: local(height: 600, width: 1200) {
          src
        }
      }
      job_title
      linkedin {
        ...ExternalUrl
      }
      recognitions
      snippets {
        image1 {
          alt
          local(breakpoints: [680, 880, 1080, 1280], lossless: true, quality: 70) {
            aspectRatio
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
      }
      socials
      title
      twitter
      whois
      works {
        category
        image {
          alt
          local(breakpoints: [620, 920, 1220, 1520, 1820], quality: 70) {
            aspectRatio
            base64
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
        name
        url {
          ...ExternalUrl
        }
      }
    }
  }
`

export default IndexPage
